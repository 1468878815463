import $ from 'jquery';
window.$ = window.jQuery = $;

// import {TimelineMax} from 'gsap';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'slick-slider';
import 'remodal';
import Inputmask from 'inputmask';
import Blazy from 'blazy';


function findVideos() {
  let videos = document.querySelectorAll('.video');

  for (let i = 0; i < videos.length; i++) {
    setupVideo(videos[i]);
  }
}

function setupVideo(video) {
  let link = video.querySelector('.video__link');
  let media = video.querySelector('.video__media');
  let button = video.querySelector('.video__button');
  let id = parseMediaURL(media);

  video.addEventListener('click', () => {
    let iframe = createIframe(id);

    link.remove();
    button.remove();
    video.appendChild(iframe);
  });

  link.removeAttribute('href');
  video.classList.add('video--enabled');
}

function parseMediaURL(media) {
  let regexp = /https:\/\/i\.ytimg\.com\/vi\/([a-zA-Z0-9_-]+)\/sddefault\.jpg/i;
  let url = media.src;
  let match = url.match(regexp);

  return match[1];
}

function createIframe(id) {
  let iframe = document.createElement('iframe');

  iframe.setAttribute('allowfullscreen', '');
  iframe.setAttribute('allow', 'autoplay');
  iframe.setAttribute('src', generateURL(id));
  iframe.classList.add('video__media');

  return iframe;
}

function generateURL(id) {
  let query = '?rel=0&showinfo=0&autoplay=1';

  return 'https://www.youtube.com/embed/' + id + query;
}

findVideos();


let blazy = new Blazy({
  offset: 400
});


$('.assortment__slider').slick({
  arrows: true,
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,

  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true
      }
    },
  ]
});


$('.review__slider').slick({
  arrows: true,
  dots: true,
  infinite: true,
  // cssEase: 'cubic-bezier(0.600, 0.280, 0.735, 0.045)',
});


$('.sertificate__slider').slick({
  arrows: true,
  dots: true,
  infinite: true,
  slidesToShow: 2,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
  ]
});


$('.sphere__list li').mouseover(function() {
  $('#' + $(this).data('image')).addClass('active');

  return false;
});



$('.sphere__list li').mouseout(function() {
  $('#' + $(this).data('image')).removeClass('active');

  return false;
});



Inputmask({'mask': '+38(999) 999-99-99'}).mask('.input-phone');


jQuery(window).on('scroll', scrollFunction);


function scrollFunction() {
  jQuery('.fixed__smm li').each(function() {
    if (jQuery(this).offset().top > jQuery('.hero').height() - 270)
      jQuery(this).addClass('visible');
    else jQuery(this).removeClass('visible');
  });

  var windowMiddle = jQuery(window).scrollTop() + jQuery(window).height() / 2;
  jQuery('.scroll__nav > div').removeClass('scroll__nav__active');
  $('.scroll__nav > div').each(function() {
    var href = $(this).attr('data-href');
    var section = jQuery(href);
    var sectionTop = section.offset().top;
    var sectionHeight = section.height();

    if (sectionTop < windowMiddle && sectionTop + sectionHeight > windowMiddle)
      jQuery(this).addClass('scroll__nav__active');
    if (
      jQuery(window).scrollTop() + jQuery(window).height() - (jQuery('footer').offset().top - 120) >= 0)
      jQuery('.scroll__nav').css({ right: '64px' });
    else
      jQuery('.scroll__nav').css({ right: '32px' });
  });
}


$(document).ready(function() {


  if (document.documentElement.clientWidth > 1200) {
    let tl2 = new TimelineMax();
    let tl3 = new TimelineMax();
    let tl4 = new TimelineMax();

    tl2.to('.construction__img2', 3, {
      x: '-120px',
      ease: Elastic.easeOut.slow
    });
    tl3.to('.construction__img3', 3, {
      x: '-120px',
      ease: Elastic.easeOut.slow
    });
    tl4.to('.construction__img4', 3, {
      x: '-100px',
      ease: Elastic.easeOut.slow
    });


    let cardWrap = document.getElementsByClassName('hero__video');
    document.body.addEventListener('mousemove', cursorPositionHandler);

    function cursorPositionHandler(e) {
      let decimalX = e.clientX / window.innerWidth - 0.5;
      let decimalY = e.clientY / window.innerHeight - 0.5;

      TweenMax.to(cardWrap, 0.5, {
        rotationY: 2 * decimalX,
        rotationX: -2 * decimalY,
        ease: Quad.easeOut,
        transformPerspective: 700,
        transformOrigin: 'center',
      });
    }


    // $(function() {
    let controller = new ScrollMagic.Controller();


    let scene2 = new ScrollMagic.Scene({
      triggerElement: '.det1',
      duration: 180,
    })
      .setPin('.det2', {
        pushFollowers: false,
      })
      .setTween(tl2)
      .addTo(controller);

    let $element = $('.construction__shadow');

    scene2.on('start', function() {
      $('.construction__box ul li:nth-of-type(2) h3').removeClass('goWhite');
      $element.removeClass('active');
    });

    scene2.on('end', function(event) {
      $('.construction__box ul li:nth-of-type(2) h3').addClass('goWhite');
      $element.addClass('active');
    });


    let scene3 = new ScrollMagic.Scene({
      triggerElement: '.det1',
      duration: 340,
    })
      .setPin('.det3', {
        pushFollowers: false
      })
      .setTween(tl3)
      .addTo(controller);

    scene3.on('start', function() {
      $('.construction__box ul li:nth-of-type(3) h3').removeClass('goWhite');
    });

    scene3.on('end', function(event) {
      $('.construction__box ul li:nth-of-type(3) h3').addClass('goWhite');
    });


    let scene4 = new ScrollMagic.Scene({
      triggerElement: '.det2',
      duration: 470,
    })
      .setPin('.det4', {
        pushFollowers: false
      })
      .setTween(tl4)
      .addTo(controller);

    scene4.on('start', function() {
      $('.construction__box ul li:nth-of-type(4) h3').removeClass('goWhite');
      $('.construction__box ul li:nth-of-type(5) h3').removeClass('goWhite');
    });

    scene4.on('end', function() {
      $('.construction__box ul li:nth-of-type(4) h3').addClass('goWhite');
      $('.construction__box ul li:nth-of-type(5) h3').addClass('goWhite');
    });
  };

});


$(window).on('load', function() {
  // if ($(window).scrollTop() >= 0) {
  //     $(".logo_head_anime").addClass("head_animations");
  // }

  $('.scrolling__link').on('click', function(event) {
    event.preventDefault();
    var id = $(this).attr('href'),

      top = $(id).offset().top;

    $('body,html').animate({
      scrollTop: top
    }, 1000);
  });


  $('.scroll__nav > div').on('click', function() {
    var href = $(this).attr('data-href');
    var section = $(href);

    $('html, body').animate({
      scrollTop: section.offset().top
    }, 1000);
  });



  scrollFunction();

});



// Scrollmagic
let controller = new ScrollMagic.Controller();

$('.advantages').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.advantages h2', 0.8, {y: '60px', opacity: 0}, {y: 0, opacity: 1, ease: Elastic.easeOut.slow}, 0.6)
    .staggerFromTo('.advantages__list li', 0.6, { opacity: 0, y: 60 }, { opacity: 1, y: 0, ease: Elastic.easeOut.slow }, 0.25);


  let scene = new ScrollMagic.Scene({
    triggerElement: '.advantages',
    triggerHook: 0.7,
    reverse: false,
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.construction').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.construction h2', 0.8, {y: '60px', opacity: 0}, {y: 0, opacity: 1, ease: Elastic.easeOut.slow}, 0.6)
    .staggerFromTo('.construction__box li', 0.6, { opacity: 0, y: 60 }, { opacity: 1, y: 0, ease: Elastic.easeOut.slow }, 0.25);

  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.7,
    reverse: false,
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);

  let $elementSMM = $('.fixed__smm svg');

  let scene7 = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.25,
    duration: '110%'
  })
    // .addIndicators()
    .addTo(controller);

  scene7.on('enter', function() {
    $elementSMM.addClass('white');
  });
  scene7.on('leave', function() {
    $elementSMM.removeClass('white');
  });
});


$('.assortment').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.assortment h2', 0.3, {y: '60px', opacity: 0}, {y: 0, opacity: 1, ease: Elastic.easeOut.slow}, 0.15)
    .staggerFromTo('.assortment__slider .assortment__slide', 0.6, { opacity: 0, y: 60 }, { opacity: 1, y: 0, ease: Elastic.easeOut.slow }, 0.15);

  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.7,
    reverse: false,
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.sphere').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.sphere h2', 0.8, {y: '60px', opacity: 0}, {y: 0, opacity: 1, ease: Elastic.easeOut.slow}, 0.4)
    .staggerFromTo('.sphere__list li', 0.6, { opacity: 0, y: 40 }, { opacity: 1, y: 0, ease: Expo.easeOut }, 0.35);

  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.85,
    reverse: false,
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.step').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.step h2', 0.8, {y: '60px', opacity: 0}, {y: 0, opacity: 1, ease: Elastic.easeOut.slow}, 0.4)
    .fromTo('.step .step1', 0.6, {opacity: 0}, {opacity: 1, ease: Elastic.easeOut.slow}, 0.75)
    .fromTo('.step .step1 h3', 0.8, {x: '-60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 0.8)
    .fromTo('.step .step1 p', 1, {x: '-60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1)
    .fromTo('.step .step1 button', 1, {x: '-60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1.24)
    .fromTo('.step .step1 .step__num', 1, {x: '-60px', scale: 0.05, opacity: 0}, {x: 0, scale: 1, opacity: 1, ease: Elastic.easeOut.slow}, 1.4)
    .fromTo('.step .step1 .arrow-1', 1, {x: '-60px', scale: 0.05, opacity: 0}, {x: 0, scale: 1, opacity: 1, ease: Elastic.easeOut.slow}, 1.7);


  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.75,
    reverse: false,
    offset: 180
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.step2').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.step .step2', 0.6, {opacity: 0}, {opacity: 1, ease: Elastic.easeOut.slow}, 1)
    .fromTo('.step .step2 h3', 0.8, {x: '60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1.2)
    .fromTo('.step .step2 p', 1, {x: '60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1.3)
    .fromTo('.step .step2 .step__num', 1, {x: '60px', scale: 0.05, opacity: 0}, {x: 0, scale: 1, opacity: 1, ease: Elastic.easeOut.slow}, 1.5)
    .fromTo('.step .step2 .arrow-2', 1, {x: '60px', scale: 0.05, opacity: 0}, {x: 0, scale: 1, opacity: 1, ease: Elastic.easeOut.slow}, 1.5);


  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.75,
    reverse: false,
    offset: 40
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.step3').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.step .step3', 0.6, {opacity: 0}, {opacity: 1, ease: Elastic.easeOut.slow}, 1.2)
    .fromTo('.step .step3 h3', 0.8, {x: '-60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1.4)
    .fromTo('.step .step3 p', 1, {x: '-60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1.6)
    .fromTo('.step .step3 .step__num', 1, {x: '-60px', scale: 0.05, opacity: 0}, {x: 0, scale: 1, opacity: 1, ease: Elastic.easeOut.slow}, 1.6)
    .fromTo('.step .step3 .arrow-3', 1, {x: '-60px', scale: 0.05, opacity: 0}, {x: 0, scale: 1, opacity: 1, ease: Elastic.easeOut.slow}, 1.75);


  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.75,
    reverse: false,
    offset: 0
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene1', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.step4').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.step .step4', 0.6, {opacity: 0}, {opacity: 1, ease: Elastic.easeOut.slow}, 1.35)
    .fromTo('.step .step4 h3', 0.8, {x: '60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1.5)
    .fromTo('.step .step4 p', 1, {x: '60px', opacity: 0}, {x: 0, opacity: 1, ease: Elastic.easeOut.slow}, 1.75)
    .fromTo('.step .step4 .step__num', 1, {x: '60px', scale: 0.05, opacity: 0}, {x: 0, scale: 1, opacity: 1, ease: Elastic.easeOut.slow}, 1.75);


  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.75,
    reverse: false,
    offset: 40
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene2', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.review').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .fromTo('.review h2', 0.8, {y: '60px', opacity: 0}, {y: 0, opacity: 1, ease: Elastic.easeOut.slow}, 0.4)
    .staggerFromTo('.review .review__img *', 0.8, { opacity: 0, y: 60 }, { opacity: 1, y: 0, ease: Elastic.easeOut.slow }, 0.5);

  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.85,
    reverse: false,
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);
});


$('.partner__box').each(function() {
  let tl11 = new TimelineMax();

  tl11
    .staggerFromTo('.partner__content *', 0.8, { opacity: 0, y: 60 }, { opacity: 1, y: 0, ease: Elastic.easeOut.slow }, 0.5)
    .fromTo('.partner__box img', 1, {rotationY: 30, x: -30, z: -30}, {rotationY: 0, x:0, z:0, ease: Elastic.easeOut.slow}, 0.7);


  let scene = new ScrollMagic.Scene({
    triggerElement: this,
    triggerHook: 0.85,
    reverse: false,
    // offset: 200
  })

    .setTween(tl11)
    // .addIndicators({ name: 'pin scene', colorEnd: 'tomato' })
    .addTo(controller);
});
